// components/Footer.js
import React from 'react';

const Footer = () => {
    return (
        <footer className="py-8 mt-16 border-t border-gray-100 dark:border-gray-800">
            <div className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row justify-between items-center">
                    <div className="flex items-center space-x-4">
                        <span className="text-sm text-gray-600 dark:text-gray-400">© 2024 Landings</span>
                    </div>
                    <div className="flex space-x-6 mt-4 md:mt-0">
                        <a href="/privacy"
                           className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200">
                            Privacy Policy
                        </a>
                        <a href="/terms"
                           className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-200">
                            Terms of Service
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;