import React, { useEffect, useRef } from 'react';

const EmailFormScript = () => {
    const formWrapperRef = useRef(null);

    useEffect(() => {
        if (!formWrapperRef.current) return;

        // Create a container div that the script expects
        const formContainer = document.createElement('div');
        formContainer.id = 'custom-form-661d4e36-9d2d-11ef-bd2d-c3b57fcdf6b8';
        formWrapperRef.current.appendChild(formContainer);

        // Load script directly into the wrapper
        const timeoutId = setTimeout(() => {
            const script = document.createElement('script');
            script.src = 'https://eomail6.com/form/661d4e36-9d2d-11ef-bd2d-c3b57fcdf6b8.js';
            script.async = true;
            script.setAttribute('data-form', '661d4e36-9d2d-11ef-bd2d-c3b57fcdf6b8');

            script.onerror = (error) => {
                console.error('Script loading error:', error);
            };

            // Add script to the wrapper instead of body
            formWrapperRef.current.appendChild(script);
        }, 100);

        // Cleanup function
        return () => {
            clearTimeout(timeoutId);
            if (formWrapperRef.current) {
                const script = formWrapperRef.current.querySelector('script[data-form="661d4e36-9d2d-11ef-bd2d-c3b57fcdf6b8"]');
                if (script) {
                    script.remove();
                }
                formWrapperRef.current.innerHTML = '';
            }
        };
    }, []);

    return (
        <div
            ref={formWrapperRef}
            className="form-wrapper w-full flex justify-center"
        />
    );
};

export default EmailFormScript;