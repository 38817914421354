// components/Header.js
import React from 'react';
import { Sun, Moon } from 'lucide-react';
import Logo from "./Logo";

const Header = ({ theme, toggleTheme }) => {
    return (
        <header className="w-full border-b border-gray-100 dark:border-gray-800">
            <div className="container mx-auto px-4">
                <div className="flex items-center justify-between h-14">
                    {/* Logo */}
                    <a href="/" className="block">
                        <Logo />
                    </a>

                    {/* Theme Toggle */}
                    <nav className="flex space-x-8">
                        <button
                            onClick={toggleTheme}
                            className="p-2 text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200 rounded-lg"
                        >
                            {theme === 'light' ? (
                                <Sun className="h-5 w-5"/>
                            ) : (
                                <Moon className="h-5 w-5"/>
                            )}
                        </button>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Header;