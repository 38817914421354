import React from 'react';

const Logo = ({ className = '' }) => {
    return (
        <div className={`flex items-center space-x-2 ${className}`}>
            <svg width="125"
                 height="28"
                 viewBox="0 0 125 28"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
                 className="text-gray-900 dark:text-white transition-colors duration-200"
            >
                <path
                    d="M124.281 11.2127L120.497 11.4455C120.433 11.1221 120.294 10.831 120.08 10.5723C119.867 10.3071 119.585 10.0969 119.236 9.94163C118.893 9.77993 118.482 9.69907 118.004 9.69907C117.363 9.69907 116.823 9.83491 116.383 10.1066C115.944 10.3718 115.724 10.7275 115.724 11.1738C115.724 11.5296 115.866 11.8304 116.151 12.0762C116.435 12.322 116.924 12.5193 117.616 12.668L120.313 13.2114C121.762 13.5089 122.842 13.9876 123.554 14.6473C124.265 15.3071 124.621 16.1739 124.621 17.2476C124.621 18.2243 124.333 19.0814 123.757 19.8188C123.188 20.5562 122.405 21.1318 121.409 21.5458C120.42 21.9533 119.278 22.1571 117.984 22.1571C116.011 22.1571 114.44 21.7463 113.269 20.9248C112.105 20.0969 111.422 18.9714 111.222 17.5484L115.287 17.3349C115.41 17.9365 115.707 18.3957 116.18 18.7127C116.652 19.0232 117.257 19.1784 117.994 19.1784C118.718 19.1784 119.301 19.0393 119.74 18.7612C120.187 18.4766 120.413 18.1111 120.42 17.6648C120.413 17.2896 120.255 16.9824 119.944 16.7431C119.634 16.4973 119.155 16.3097 118.508 16.1803L115.927 15.6661C114.472 15.375 113.389 14.8705 112.677 14.1525C111.972 13.4345 111.619 12.5193 111.619 11.4067C111.619 10.4494 111.878 9.62468 112.396 8.93257C112.92 8.24046 113.654 7.70683 114.598 7.33166C115.549 6.9565 116.662 6.76892 117.936 6.76892C119.818 6.76892 121.299 7.16672 122.38 7.96233C123.466 8.75793 124.1 9.84137 124.281 11.2127Z"
                    fill="currentColor"/>
                <path
                    d="M103.218 27.7651C101.879 27.7651 100.731 27.5808 99.7735 27.2121C98.8226 26.8498 98.0658 26.355 97.5031 25.7276C96.9403 25.1002 96.5749 24.3951 96.4067 23.6124L100.229 23.0982C100.346 23.3957 100.53 23.6739 100.783 23.9326C101.035 24.1914 101.368 24.3983 101.782 24.5536C102.202 24.7153 102.713 24.7961 103.315 24.7961C104.214 24.7961 104.955 24.5762 105.537 24.1364C106.125 23.703 106.42 22.9753 106.42 21.9533V19.2269H106.245C106.064 19.6409 105.792 20.0322 105.43 20.4009C105.068 20.7696 104.602 21.0704 104.033 21.3032C103.464 21.5361 102.784 21.6525 101.995 21.6525C100.876 21.6525 99.8576 21.3938 98.939 20.8763C98.027 20.3524 97.2993 19.5536 96.756 18.4798C96.2191 17.3996 95.9507 16.0348 95.9507 14.3854C95.9507 12.6971 96.2256 11.287 96.7754 10.1551C97.3252 9.02313 98.0561 8.17578 98.9682 7.61304C99.8867 7.05029 100.892 6.76892 101.986 6.76892C102.82 6.76892 103.519 6.91122 104.081 7.19583C104.644 7.47397 105.097 7.82326 105.44 8.2437C105.789 8.65767 106.057 9.06518 106.245 9.46621H106.4V6.96297H110.504V22.0115C110.504 23.2793 110.194 24.3401 109.573 25.1939C108.952 26.0478 108.092 26.6881 106.992 27.115C105.899 27.5484 104.641 27.7651 103.218 27.7651ZM103.305 18.5477C103.971 18.5477 104.534 18.3828 104.993 18.0529C105.459 17.7166 105.815 17.2379 106.061 16.6169C106.313 15.9895 106.439 15.2392 106.439 14.366C106.439 13.4927 106.316 12.7359 106.07 12.0956C105.825 11.4488 105.469 10.9475 105.003 10.5917C104.537 10.2359 103.971 10.0581 103.305 10.0581C102.626 10.0581 102.054 10.2424 101.588 10.6111C101.122 10.9733 100.77 11.4779 100.53 12.1247C100.291 12.7715 100.171 13.5186 100.171 14.366C100.171 15.2263 100.291 15.9701 100.53 16.5975C100.776 17.2185 101.129 17.7004 101.588 18.0432C102.054 18.3796 102.626 18.5477 103.305 18.5477Z"
                    fill="currentColor"/>
                <path
                    d="M85.5537 13.2502V21.866H81.4204V6.96297H85.3596V9.59234H85.5343C85.8641 8.72559 86.4172 8.03995 87.1934 7.53542C87.9696 7.02442 88.9107 6.76892 90.0168 6.76892C91.0517 6.76892 91.9541 6.99531 92.7238 7.44809C93.4935 7.90088 94.0919 8.54771 94.5188 9.38859C94.9457 10.223 95.1591 11.2191 95.1591 12.377V21.866H91.0259V13.1143C91.0323 12.2023 90.7995 11.4908 90.3273 10.9798C89.8551 10.4623 89.205 10.2036 88.3771 10.2036C87.8208 10.2036 87.3292 10.3233 86.9023 10.5626C86.4819 10.8019 86.152 11.1512 85.9127 11.6105C85.6798 12.0632 85.5601 12.6098 85.5537 13.2502Z"
                    fill="currentColor"/>
                <path
                    d="M75.8882 21.866V6.96297H80.0214V21.866H75.8882ZM77.9645 5.04188C77.35 5.04188 76.8228 4.83813 76.383 4.43062C75.9496 4.01665 75.7329 3.52182 75.7329 2.94614C75.7329 2.37693 75.9496 1.88857 76.383 1.48107C76.8228 1.06709 77.35 0.860107 77.9645 0.860107C78.579 0.860107 79.1029 1.06709 79.5363 1.48107C79.9761 1.88857 80.1961 2.37693 80.1961 2.94614C80.1961 3.52182 79.9761 4.01665 79.5363 4.43062C79.1029 4.83813 78.579 5.04188 77.9645 5.04188Z"
                    fill="currentColor"/>
                <path
                    d="M65.9231 22.1085C64.7911 22.1085 63.7659 21.8174 62.8474 21.2353C61.9353 20.6467 61.2109 19.7831 60.674 18.6447C60.1436 17.4998 59.8784 16.0962 59.8784 14.4338C59.8784 12.7262 60.1533 11.3064 60.7031 10.1744C61.2529 9.03601 61.9839 8.18543 62.8959 7.62268C63.8144 7.05347 64.8202 6.76886 65.9134 6.76886C66.7478 6.76886 67.4431 6.91117 67.9994 7.19577C68.5621 7.47391 69.0149 7.8232 69.3577 8.24364C69.707 8.65761 69.9722 9.06512 70.1534 9.46615H70.2795V1.99524H74.403V21.8659H70.328V19.4791H70.1534C69.9593 19.8931 69.6844 20.3038 69.3286 20.7113C68.9794 21.1124 68.5233 21.4455 67.9606 21.7107C67.4043 21.9759 66.7251 22.1085 65.9231 22.1085ZM67.2329 18.8194C67.8991 18.8194 68.4619 18.6382 68.9211 18.276C69.3869 17.9073 69.7426 17.3931 69.9884 16.7333C70.2407 16.0735 70.3668 15.3006 70.3668 14.4144C70.3668 13.5283 70.2439 12.7585 69.9981 12.1052C69.7523 11.4519 69.3966 10.9474 68.9308 10.5916C68.4651 10.2359 67.8991 10.058 67.2329 10.058C66.5537 10.058 65.9813 10.2424 65.5156 10.611C65.0498 10.9797 64.6973 11.4907 64.458 12.144C64.2187 12.7973 64.099 13.5541 64.099 14.4144C64.099 15.2812 64.2187 16.0477 64.458 16.7139C64.7038 17.3737 65.0563 17.8911 65.5156 18.2663C65.9813 18.635 66.5537 18.8194 67.2329 18.8194Z"
                    fill="currentColor"/>
                <path
                    d="M49.4809 13.2502V21.866H45.3477V6.96297H49.2869V9.59234H49.4615C49.7914 8.72559 50.3444 8.03995 51.1206 7.53542C51.8968 7.02442 52.838 6.76892 53.9441 6.76892C54.979 6.76892 55.8813 6.99531 56.6511 7.44809C57.4208 7.90088 58.0191 8.54771 58.446 9.38859C58.8729 10.223 59.0864 11.2191 59.0864 12.377V21.866H54.9531V13.1143C54.9596 12.2023 54.7267 11.4908 54.2545 10.9798C53.7824 10.4623 53.1323 10.2036 52.3043 10.2036C51.7481 10.2036 51.2565 10.3233 50.8296 10.5626C50.4091 10.8019 50.0792 11.1512 49.8399 11.6105C49.607 12.0632 49.4874 12.6098 49.4809 13.2502Z"
                    fill="currentColor"/>
                <path
                    d="M35.6607 22.1474C34.7098 22.1474 33.8625 21.9824 33.1186 21.6525C32.3748 21.3162 31.7861 20.8214 31.3528 20.1681C30.9259 19.5083 30.7124 18.6868 30.7124 17.7036C30.7124 16.8757 30.8644 16.1803 31.1684 15.6176C31.4724 15.0548 31.8864 14.6021 32.4103 14.2592C32.9343 13.9164 33.5294 13.6577 34.1956 13.483C34.8683 13.3084 35.5733 13.1855 36.3107 13.1143C37.1775 13.0238 37.8761 12.9397 38.4065 12.8621C38.9369 12.778 39.3217 12.6551 39.5611 12.4934C39.8004 12.3317 39.9201 12.0924 39.9201 11.7754V11.7172C39.9201 11.1027 39.726 10.6273 39.3379 10.2909C38.9563 9.95457 38.4129 9.78639 37.7079 9.78639C36.964 9.78639 36.3722 9.95134 35.9323 10.2812C35.4925 10.6046 35.2014 11.0121 35.0591 11.5037L31.2363 11.1933C31.4304 10.2877 31.812 9.50502 32.3812 8.84525C32.9504 8.17901 33.6846 7.66802 34.5837 7.31226C35.4893 6.95003 36.5371 6.76892 37.7273 6.76892C38.5552 6.76892 39.3476 6.86595 40.1044 7.05999C40.8677 7.25404 41.5436 7.55482 42.1322 7.96233C42.7273 8.36983 43.1963 8.89377 43.5391 9.53413C43.8819 10.168 44.0533 10.9281 44.0533 11.8142V21.866H40.1335V19.7994H40.0171C39.7778 20.2651 39.4576 20.6758 39.0565 21.0316C38.6555 21.3809 38.1736 21.6558 37.6109 21.8563C37.0481 22.0503 36.3981 22.1474 35.6607 22.1474ZM36.8444 19.2948C37.4524 19.2948 37.9893 19.1752 38.455 18.9358C38.9207 18.69 39.2862 18.3602 39.5514 17.9462C39.8166 17.5322 39.9492 17.0633 39.9492 16.5393V14.9578C39.8198 15.0419 39.6419 15.1195 39.4155 15.1907C39.1956 15.2554 38.9466 15.3168 38.6684 15.375C38.3903 15.4268 38.1122 15.4753 37.834 15.5206C37.5559 15.5594 37.3036 15.5949 37.0772 15.6273C36.5921 15.6984 36.1684 15.8116 35.8062 15.9669C35.444 16.1221 35.1626 16.3323 34.9621 16.5975C34.7616 16.8563 34.6613 17.1797 34.6613 17.5678C34.6613 18.1305 34.8651 18.5607 35.2726 18.8582C35.6865 19.1493 36.2105 19.2948 36.8444 19.2948Z"
                    fill="currentColor"/>
                <path d="M30.0127 1.99524V21.8659H25.8794V1.99524H30.0127Z" fill="currentColor"/>
                <path
                    d="M10.0813 1.98016C10.6876 1.70941 11.3875 1.70941 11.9938 1.98016L20.8889 5.94319C21.2348 6.09622 21.4545 6.42974 21.4545 6.79858C21.4545 7.16742 21.2348 7.50094 20.8889 7.65397L11.9938 11.617C11.3875 11.8877 10.6876 11.8877 10.0813 11.617L1.18621 7.65397C0.840338 7.49702 0.620605 7.16349 0.620605 6.79858C0.620605 6.43367 0.840338 6.09622 1.18621 5.94319L10.0813 1.98016ZM18.7241 10.0004L20.8889 10.9657C21.2348 11.1187 21.4545 11.4522 21.4545 11.821C21.4545 12.1899 21.2348 12.5234 20.8889 12.6764L11.9938 16.6395C11.3875 16.9102 10.6876 16.9102 10.0813 16.6395L1.18621 12.6764C0.840338 12.5195 0.620605 12.186 0.620605 11.821C0.620605 11.4561 0.840338 11.1187 1.18621 10.9657L3.35099 10.0004L9.53606 12.7549C10.4882 13.1787 11.5869 13.1787 12.5391 12.7549L18.7241 10.0004ZM12.5391 17.7774L18.7241 15.0229L20.8889 15.9881C21.2348 16.1411 21.4545 16.4747 21.4545 16.8435C21.4545 17.2123 21.2348 17.5459 20.8889 17.6989L11.9938 21.6619C11.3875 21.9327 10.6876 21.9327 10.0813 21.6619L1.18621 17.6989C0.840338 17.5419 0.620605 17.2084 0.620605 16.8435C0.620605 16.4786 0.840338 16.1411 1.18621 15.9881L3.35099 15.0229L9.53606 17.7774C10.4882 18.2011 11.5869 18.2011 12.5391 17.7774Z"
                    fill="currentColor"/>
            </svg>
        </div>
    );
};

export default Logo;