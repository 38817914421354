// App.js
import React, {useState, useEffect} from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import WebsiteCard from "./components/WebsiteCard";
import EmailFormScript from "./components/EmailFormScript";

const App = () => {
    const [theme, setTheme] = useState('dark');

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') ||
            (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
        setTheme(savedTheme);
        document.documentElement.classList.toggle('dark', savedTheme === 'dark');
    }, []);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
        document.documentElement.classList.toggle('dark', newTheme === 'dark');
    };

    // Sample data for the grid
    const websites = Array.from({length: 8}, (_, i) => ({
        id: i + 1,
        title: ['Stripe', 'Notion', 'Linear', 'Vercel', 'Rive', 'Figma', 'Tailwind CSS', 'Miro'][i],
        url: ['stripe.com', 'notion.so', 'linear.app', 'vercel.com', 'rive.app', 'figma.com', 'tailwindcss.com', 'miro.com'][i],
        screenshot: `/images/websites/thumbs/${[
            'stripe',
            'notion',
            'linear',
            'vercel',
            'rive',
            'figma',
            'tailwind',
            'miro'
        ][i].toLowerCase()}@2x.webp`
    }));

    return (
        <div className="min-h-screen dark:bg-gray-900">
            <Header theme={theme} toggleTheme={toggleTheme}/>

            {/* Hero Section */}
            <div className="pt-12 md:pt-24 pb-12">
                <div className="container mx-auto px-4 text-center max-w-3xl flex flex-col align-center gap-3">
                    <h1 className="text-4xl md:text-6xl md:leading-none font-bold text-gray-900 dark:text-white mb-4">
                        Landing page inspiration that converts
                    </h1>
                    <p className="text-lg text-gray-600 dark:text-gray-400 mb-8 max-w-lg mx-auto">
                        Explore hand-picked landing pages from top-performing websites to inspire your next project.
                    </p>
                </div>
            </div>

            {/* Sign Up Form Section */}
            <div className="container mx-auto py-4 max-w-lg pb-12 flex flex-col items-center">

                <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2 text-center">
                    Join the waiting list
                </h2>
                <p className="text-gray-600 dark:text-gray-400 text-center max-w-sm mb-2">
                    Get notified when it's ready. We don't do spam or any other BS. Pinky promise. 🤙
                </p>

                <EmailFormScript/>

            </div>

            {/* Grid Section with Gradient Fade */}
            <div className="pyt-16 relative">
                {/* Gradient fade overlay */}
                <div
                    className="absolute top-0 left-0 right-0 h-[100px] bg-gradient-to-b from-white dark:from-gray-900 to-transparent z-10"></div>

                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        {websites.map((website) => (
                            <WebsiteCard key={website.id} website={website}/>
                        ))}
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
};

export default App;