import React from 'react';

const WebsiteCard = ({ website }) => {
    return (
        <div
            className="group transition-all duration-300 relative overflow-hidden"
        >
            {/* Screenshot Container */}
            <div className="relative overflow-hidden rounded-xl bg-gray-100 dark:bg-gray-800">
                <img
                    src={website.screenshot}
                    alt={`Screenshot of ${website.title}`}
                    className="w-full h-56 object-cover object-top transition-transform duration-300 group-hover:scale-105"
                    loading="lazy"
                />
            </div>

            {/* Title Bar */}
            <div className="flex items-center justify-between absolute bottom-0 right-0 bg-black px-4 py-2 hidden">
                <div className="flex items-center space-x-2">
                    <h3 className="text-sm font-medium text-gray-900 dark:text-white transition-colors duration-200 truncate group-hover:text-gray-600 dark:group-hover:text-gray-300">
                        {website.title}
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default WebsiteCard;